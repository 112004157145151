import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Store } from 'rc-field-form/lib/interface'
import './styles.scss'
import {
  Typography,
  Card,
  Form,
  Select,
  Radio,
  Row,
  Col,
  Input,
  Button,
  Modal,
  DatePicker,
  TimePicker,
  notification,
  InputNumber,
} from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import dayjs, { Dayjs } from 'dayjs'
import { Country, PhoneNumberInput } from '../../components/PhoneNumberInput'
import _ from 'lodash'

import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import { PlacesAutocomplete } from '../../components/PlacesAutocomplete'

dayjs.extend(localizedFormat)

const { Option } = Select
const { Text } = Typography
const { Group } = Input

const disabledDate = (current?: Dayjs) => !!current && current.isBefore(dayjs(), 'day')

type WorkflowStep = {
  id: string
  name: string
  type: string
  place: {
    address: string
    longitude: number
    latitude: number
  } | null
  messageTemplateID: string | null
}

type Workflow = {
  id: string
  name: string
  tripOptimizationHeuristic: string
  steps: WorkflowStep[]
}

interface IStoreBusinessHours {
  sundayStartAt: Date | null
  sundayEndAt: Date | null
  mondayStartAt: Date | null
  mondayEndAt: Date | null
  tuesdayStartAt: Date | null
  tuesdayEndAt: Date | null
  wednesdayStartAt: Date | null
  wednesdayEndAt: Date | null
  thursdayStartAt: Date | null
  thursdayEndAt: Date | null
  fridayStartAt: Date | null
  fridayEndAt: Date | null
  saturdayStartAt: Date | null
  saturdayEndAt: Date | null
}

interface IStore {
  id: string
  name: string
  color: string
  iconUrl: string | null
  kioskTripsPending: boolean
  place: {
    id: string
    latitude: number
    longitude: number
    address: string
  }
  account: {
    id: string
    country: string
  }
  workflows: Workflow[]
  storeBusinessHours: IStoreBusinessHours | null
}

type Language = 'en' | 'fr' | 'es'

i18n.changeLanguage('en')

const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const Kiosk: React.FC = () => {
  const { shortUrl } = useParams<{ shortUrl: string }>()
  const [form] = useForm()
  const [language, setLanguage] = useState<Language>('en')
  const [token, setToken] = useState<string>('')
  const [store, setStore] = useState<IStore | null>(null)
  const [workflow, setWorkflow] = useState<Workflow | null>(null)
  const [create, setCreate] = useState(true)
  const [countdown, setCountdown] = useState(10)
  const [loading, setLoading] = useState<boolean>(false)
  const [t] = useTranslation()
  const [scheduledAtDate, setScheduledAtDate] = useState(dayjs())
  const [closed, setClosed] = useState(false)

  const weekDay = weekDays[scheduledAtDate.day()]

  useEffect(() => {
    const authenticate = async () => {
      if (!shortUrl) return

      try {
        const response = await fetch(`${window.SC.apiUrl}/v2/auth/kiosk`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            kioskShortUrl: shortUrl,
          }),
        })

        const json = await response.json()

        if (!json.error) {
          setToken(json.token)
        }
      } catch {}
    }

    authenticate()
  }, [shortUrl])

  useEffect(() => {
    if (shortUrl && token) {
      fetch(`${window.SC.apiUrl}/v2/stores/kiosk/${shortUrl}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
          } else {
            setStore({
              ...json,
            })
          }
        })
        .catch(() => {})
    }
  }, [shortUrl, token])

  const handleRestart = useCallback(() => {
    setCreate(true)
    form.resetFields()
    setWorkflow(null)
  }, [form])

  const changeLanguage = useCallback((language: Language) => {
    setLanguage(language)
    i18n.changeLanguage(language)
  }, [])

  useEffect(() => {
    if (countdown && !create) {
      const timer = setInterval(() => setCountdown((countdown) => countdown - 1), 1000)

      return () => clearInterval(timer)
    } else if (!create) {
      handleRestart()
    }
  }, [countdown, create, handleRestart])

  useEffect(() => {
    form.resetFields()
  }, [form, workflow])

  useEffect(() => {
    if (store && store.storeBusinessHours) {
      const startAtField = `${weekDay}StartAt` as keyof IStoreBusinessHours
      const endAtField = `${weekDay}EndAt` as keyof IStoreBusinessHours

      if (store.storeBusinessHours[startAtField] && store.storeBusinessHours[endAtField]) {
        setClosed(false)
      } else {
        setClosed(true)
      }
    }
  }, [closed, weekDay, store])

  function handleOnFinish(values: Store) {
    const scheduledAt =
      values.scheduledAt &&
      dayjs(values.scheduledAt.date)
        .hour(values.scheduledAt.time.hour())
        .minute(values.scheduledAt.time.minute())
        .toISOString()

    setLoading(true)
    fetch(`${window.SC.apiUrl}/v2/trips/kiosk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...values,
        storeId: store?.id,
        workflowId: workflow?.id,
        scheduledAt,
        tripOptimizationHeuristic: workflow?.tripOptimizationHeuristic,
        language: language,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false)

        if (json.error) {
          notification['error']({
            message: t(`error.server.${json.error.message}`),
          })
        } else {
          setCountdown(10)
          setCreate(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  if (!store || !shortUrl) {
    return null
  }

  const hasStoreImage: boolean = !!store?.iconUrl

  const disabledTime = (now: Dayjs) => {
    const disabledHours: number[] = []

    if (store.storeBusinessHours) {
      const startAtField = `${weekDay}StartAt` as keyof IStoreBusinessHours
      const endAtField = `${weekDay}EndAt` as keyof IStoreBusinessHours

      if (store.storeBusinessHours[startAtField] && store.storeBusinessHours[endAtField]) {
        const startTimeHash =
          dayjs(store.storeBusinessHours[startAtField]).hour() +
          dayjs(store.storeBusinessHours[startAtField]).minute() / 59
        const endTimeHash =
          dayjs(store.storeBusinessHours[endAtField]).hour() +
          dayjs(store.storeBusinessHours[endAtField]).minute() / 59

        for (let h = 0; h < 24; h++) {
          if (h < startTimeHash || h >= endTimeHash) {
            disabledHours.push(h)
          }
        }
      } else {
        // All hours are disabled on closed days
        for (let h = 0; h < 24; h++) {
          disabledHours.push(h)
        }
      }
    }

    return {
      disabledHours: () => disabledHours,
    }
  }

  return (
    <div>
      {create ? (
        <Card
          bordered={false}
          className="trip-create"
          title={
            <div>
              {hasStoreImage && (
                <img
                  style={{ marginRight: 15 }}
                  src={`${window.SC.apiUrl}/v2/stores/${store.id}/logo`}
                  width={40}
                  height={40}
                  alt="logo"
                />
              )}
              <Text className="text-dark mb-0 store-title">{store.name}</Text>
            </div>
          }
        >
          <Row style={{ marginBottom: 15 }}>
            <Col xs={12}>
              <Text>{t('kiosk.chooseLanguage')}</Text>
              <Radio.Group
                style={{ width: '100%', marginTop: 8 }}
                optionType="button"
                buttonStyle="solid"
                options={[
                  { label: t('general.english'), value: 'en' },
                  { label: t('general.french'), value: 'fr' },
                  { label: t('general.spanish'), value: 'es' },
                ]}
                value={language}
                onChange={(language) => changeLanguage(language.target.value)}
                disabled={loading}
              />
            </Col>
            <Col xs={12}>
              <Text>{t('kiosk.tripService')}</Text>
              <Select
                style={{ width: '100%', marginTop: 8 }}
                placeholder={t('kiosk.chooseTripService')}
                onChange={(workflowId) => {
                  const workflow = store?.workflows.find((w) => w.id === workflowId)!

                  setWorkflow(workflow)
                }}
                value={workflow?.id || undefined}
                disabled={loading}
              >
                {store.workflows.map((workflow) => (
                  <Option key={workflow.id} value={workflow.id}>
                    {workflow.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          {workflow && (
            <Form
              layout="vertical"
              onFinish={handleOnFinish}
              form={form}
              disabled={loading}
              initialValues={{
                seats: 1,
                state: store.kioskTripsPending ? 'PENDING' : 'REQUESTED',
                steps: workflow.steps,
                scheduledAt: { date: scheduledAtDate },
              }}
            >
              <Row gutter={10}>
                <Col xs={8} md={6}>
                  <Form.Item
                    name="phoneNumber"
                    label={t('general.phoneNumber')}
                    rules={[{ required: true, message: t('error.required') }]}
                  >
                    <PhoneNumberInput country={store.account.country as Country} />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="firstName"
                    label={t('general.firstName')}
                    rules={[{ required: true, message: t('error.required') }]}
                  >
                    <Input max={50} placeholder={t('kiosk.john')} />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="lastName"
                    label={t('general.lastName')}
                    rules={[{ required: true, message: t('error.required') }]}
                  >
                    <Input max={50} placeholder={t('kiosk.doe')} />
                  </Form.Item>
                </Col>
                <Col xs={4} md={6}>
                  <Form.Item
                    name="seats"
                    label={t('kiosk.seats')}
                    rules={[{ required: true, message: t('error.required') }]}
                  >
                    <Select>
                      {_.range(1, 31).map((i) => (
                        <Option key={i} value={i}>
                          {i}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24}>
                  <Form.Item name="notes" label={t('kiosk.notes')}>
                    <Input max={500} />
                  </Form.Item>
                </Col>
              </Row>
              {workflow.steps.map((s, i) => (
                <div key={i}>
                  <Form.Item
                    name={['steps', i, 'type']}
                    rules={[{ required: true, message: t('error.required') }]}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['steps', i, 'name']}
                    style={{ display: 'none' }}
                    rules={[{ required: true, message: t('error.required') }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['steps', i, 'position']}
                    style={{ display: 'none' }}
                    rules={[{ required: true, message: t('error.required') }]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    name={['steps', i, 'messageTemplate', 'id']}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </Form.Item>
                  {s.type === 'place' && (
                    <>
                      <Form.Item
                        name={['steps', i, 'place', 'address']}
                        rules={[{ required: true, message: t('error.required') }]}
                      >
                        {s.place?.address ? (
                          s.place.address
                        ) : (
                          <PlacesAutocomplete
                            placeholder={t('kiosk.enterAddress')}
                            token={token}
                            kioskShortUrl={shortUrl}
                            onSelect={(place) => {
                              if (_.has(place, 'longitude') && _.has(place, 'latitude')) {
                                const steps: WorkflowStep[] = form.getFieldValue('steps')
                                const maybeStep = steps.find(
                                  (s) => s.place && s.place.address === place.address,
                                )

                                if (maybeStep) {
                                  maybeStep.place!.longitude = place.longitude
                                  maybeStep.place!.latitude = place.latitude
                                  form.setFieldsValue(steps)
                                }
                              }
                            }}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        name={['steps', i, 'place', 'longitude']}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={['steps', i, 'place', 'latitude']}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}
                </div>
              ))}
              <Form.Item name="state" rules={[{ required: true, message: t('error.required') }]}>
                <Radio.Group>
                  {store.kioskTripsPending ? (
                    <Radio.Button value="PENDING">{t('kiosk.pending')}</Radio.Button>
                  ) : (
                    <Radio.Button value="REQUESTED">{t('kiosk.asap')}</Radio.Button>
                  )}
                  <Radio.Button value="SCHEDULED">{t('kiosk.scheduled')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValue, nextValue) => {
                  if (prevValue.state !== nextValue.state) {
                    return true
                  }

                  return (
                    prevValue['scheduledAt'].date.toISOString() !==
                    nextValue['scheduledAt'].date.toISOString()
                  )
                }}
              >
                {({ getFieldValue }) =>
                  getFieldValue('state') === 'SCHEDULED' && (
                    <Row gutter={10}>
                      <Col xs={24} md={16}>
                        <Form.Item label={t('kiosk.scheduledAt')}>
                          <Group compact>
                            <Form.Item
                              name={['scheduledAt', 'date']}
                              noStyle
                              rules={[{ required: true, message: t('error.required') }]}
                            >
                              <DatePicker
                                style={{ width: '50%' }}
                                disabledDate={disabledDate}
                                allowClear={false}
                                onChange={(value) => {
                                  if (value) {
                                    setScheduledAtDate(value)
                                  }
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name={['scheduledAt', 'time']}
                              noStyle
                              rules={[{ required: true, message: t('error.required') }]}
                            >
                              {closed ? (
                                <span
                                  style={{
                                    lineHeight: '32px',
                                    color: 'red',
                                    paddingLeft: '10px',
                                  }}
                                >
                                  {`${t('kiosk.closed')} ${weekDay
                                    .charAt(0)
                                    .toUpperCase()}${weekDay.slice(1)}`}
                                </span>
                              ) : (
                                (language === 'en' && (
                                  <TimePicker
                                    minuteStep={15}
                                    use12Hours
                                    format="h:mm a"
                                    style={{ width: '50%' }}
                                    disabledTime={disabledTime}
                                  />
                                )) || (
                                  <TimePicker
                                    minuteStep={15}
                                    format="h:mm"
                                    style={{ width: '50%' }}
                                    disabledTime={disabledTime}
                                  />
                                )
                              )}
                            </Form.Item>
                          </Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  )
                }
              </Form.Item>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 10 }}
                      loading={loading}
                    >
                      {t('general.confirm')}
                    </Button>
                    <Button
                      onClick={() => {
                        Modal.confirm({
                          title: t('general.areYouSure'),
                          okText: t('general.yes'),
                          cancelText: t('general.no'),
                          okButtonProps: {
                            danger: true,
                          },
                          onOk: () => {
                            handleRestart()
                          },
                        })
                      }}
                    >
                      {t('general.cancel')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Card>
      ) : (
        <Card bordered={false}>
          <p style={{ textAlign: 'center' }}>
            <CheckCircleTwoTone
              style={{ fontSize: 40 }}
              twoToneColor="mediumseagreen"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          </p>
          <p style={{ textAlign: 'center' }}>
            {t('kiosk.thankYou')} {store.name}! {t('kiosk.aTripFor')}{' '}
            {form.getFieldValue('firstName')} {form.getFieldValue('lastName')}{' '}
            {['REQUESTED', 'PENDING'].includes(form.getFieldValue('state'))
              ? t('kiosk.hasBeenRequested')
              : `${t('kiosk.hasBeenScheduledFor')} ${form
                  .getFieldValue(['scheduledAt', 'date'])
                  .locale(language)
                  .format('LL')} ${t('general.at')} ${form
                  .getFieldValue(['scheduledAt', 'time'])
                  .locale(language)
                  .format('LT')}`}
            .
          </p>
          <p style={{ textAlign: 'center' }}>
            {t('kiosk.confirmationMessage')} {countdown} {t('kiosk.seconds')}.
          </p>
          <p style={{ marginTop: 10, textAlign: 'center' }}>
            <Button onClick={() => handleRestart()}>{t('general.done')}</Button>
          </p>
        </Card>
      )}
      <p style={{ textAlign: 'right', marginTop: 20, marginRight: 20 }}>
        v{process.env.REACT_APP_VERSION}
      </p>
    </div>
  )
}
