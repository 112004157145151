import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Card, Rate } from 'antd'
import { useParams } from 'react-router'
import io from 'socket.io-client'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

import { DynamicMap } from '../../components/MapboxMaps/DynamicMap'
import { StoreMarker } from '../../components/MapboxMaps/StoreMarker'
import { TripMarker } from '../../components/MapboxMaps/TripMarker'
import { VehicleMarker } from '../../components/MapboxMaps/VehicleMarker'
import i18n from '../../i18n'
import './styles.css'
import { TripPreview } from '../../components/MapboxMaps/TripPreview'
import { VehicleType } from '../../utility/SVG'

dayjs.extend(calendar)

interface ITrip {
  id: string
  state: string
  createdAt: Date
  confirmedAt: Date | null
  finishedAt: Date | null
  scheduledAt: Date | null
  reference: string | null
  steps: {
    id: string
    name: string
    finishedAt: Date | null
    place: {
      latitude: number
      longitude: number
      address: string
    } | null
  }[]
  customer: {
    id: string
    firstName: string
    lastName: string
    language: string
  } | null
  customerReview: {
    id: string
    rating: number
    comment: string
  } | null
  workflow: {
    id: string
    name: string
    customerReviewsEnabled: boolean
  }
  store: {
    id: string
    name: string
    color: string
    iconUrl: string | null
    account: {
      id: string
    }
    place: {
      id: string
      latitude: number
      longitude: number
      address: string
    }
  }
  vehicle: {
    id: string
    make: string
    model: string
    type: VehicleType
    color: string
    licensePlate: string
    driver: {
      id: string
      firstName: string
      lastName: string
      phoneNumber: string
    } | null
    vehicleCoordinate: {
      id: string
      closestRoadLatitude: number
      closestRoadLongitude: number
      heading: number
    } | null
  } | null
  drivers: {
    id: string
    firstName: string
    lastName: string
    phoneNumber: string
    vehicle: {
      id: string
      make: string
      model: string
      type: VehicleType
      color: string
      licensePlate: string
      vehicleCoordinate: {
        id: string
        closestRoadLatitude: number
        closestRoadLongitude: number
        heading: number
      } | null
    } | null
  }[]
}

const { TextArea } = Input

export const TripTrack: React.FC = () => {
  const { shortUrl } = useParams<{ shortUrl: string }>()
  const [trip, setTrip] = useState<ITrip | null>(null)
  const [token, setToken] = useState<string>('')
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })
  const [t] = useTranslation()
  const [reviewed, setReviewed] = useState<boolean>(false)
  const [rating, setRating] = useState<number>()
  const [vehicle, setVehicle] = useState<any>(null)

  useEffect(() => {
    if (shortUrl) {
      fetch(`${window.SC.apiUrl}/v2/auth/customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shortUrl,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
          } else {
            setToken(json.token)
          }
        })
        .catch((_) => {})
    }
  }, [shortUrl])

  useEffect(() => {}, [token])

  useEffect(() => {
    let socket: SocketIOClient.Socket | null = null
    if (shortUrl && token) {
      fetch(`${window.SC.apiUrl}/v2/trips/track/${shortUrl}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
          } else {
            if (json.customer) {
              i18n.changeLanguage(json.customer.language)
            }

            setCenter({
              lat: json.store.place.latitude,
              lng: json.store.place.longitude,
            })

            let lastFinishedStep = json.steps
              .slice()
              .reverse()
              .find((s: any) => s.finishedAt)

            let activeVehicleId = json.vehicle.id

            if (lastFinishedStep) {
              activeVehicleId = lastFinishedStep.vehicle.id

              setTrip({
                ...json,
                vehicle: lastFinishedStep.vehicle,
              })

              setVehicle(lastFinishedStep.vehicle)
            } else {
              setTrip(json)
              setVehicle(json.vehicle)
            }

            if (json.state === 'CONFIRMED') {
              socket = io(`${window.SC.apiUrl}/stores`, {
                path: '/ws',
                transports: ['websocket'],
              })

              socket.on('connect', () => {
                if (socket) {
                  socket.emit('join', json.store.id)
                  socket.on('/trips/completed', (message: string) => {
                    const tripMessage = JSON.parse(message)

                    if (json && json.id === tripMessage.id) {
                      setTrip((t) => ({
                        ...t,
                        ...tripMessage,
                      }))
                    }
                  })

                  socket.on('/trips/cancelled', (message: string) => {
                    const tripMessage = JSON.parse(message)

                    if (json && json.id === tripMessage.id) {
                      setTrip((t) => ({
                        ...t,
                        ...tripMessage,
                      }))
                    }
                  })

                  socket.on('/vehicles/coordinates', (message: string) => {
                    const vehicleMessage = JSON.parse(message)

                    // Check that the coordinate update is coming from the correct vehicle
                    if (vehicleMessage?.id === activeVehicleId) {
                      setVehicle(vehicleMessage)
                    }
                  })
                }
              })
            }
          }
        })
        .catch(() => {})
    }

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [shortUrl, token])

  if (!trip) {
    return (
      <span style={{ display: 'inline-block', margin: 20, fontFamily: 'sans-serif' }}>
        <i className="fas fa-spinner fa-spin" /> {t('tripTrack.loading')}...
      </span>
    )
  }

  if (trip.state === 'CANCELLED') {
    return (
      <span style={{ display: 'inline-block', margin: 20, fontFamily: 'sans-serif' }}>
        {t('tripTrack.tripCancelled')}
      </span>
    )
  }

  const onFinish = (values: any) => {
    fetch(`${window.SC.apiUrl}/v2/customerReviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tripID: trip.id,
        rating: values.rating,
        comment: values.comment,
      }),
    })
      .then((_) => setReviewed(true))
      .catch((_) => {})
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  if (trip.state === 'COMPLETED') {
    if (trip.workflow.customerReviewsEnabled)
      return reviewed || trip.customerReview ? (
        <Card bordered={false}>
          <span>{t('tripTrack.thanksReview')}</span>
        </Card>
      ) : (
        <Card bordered={false}>
          <Form
            name="review"
            initialValues={{ comment: '' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <p>{t('tripTrack.tripCompleteReview')}</p>
            <Form.Item
              name="rating"
              rules={[{ required: true, message: t('tripTrack.leaveRating') as string }]}
            >
              <Rate value={rating} allowClear={false} onChange={setRating} />
            </Form.Item>
            <p>{t('tripTrack.leaveComment')}</p>
            <Form.Item
              name="comment"
              rules={[
                {
                  required: !!(rating && rating <= 2),
                  message: t('tripTrack.letUsKnow') as string,
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('tripTrack.submit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )
    else
      return (
        <Card bordered={false}>
          <span>{t('tripTrack.tripCompleteNoReview')}</span>
        </Card>
      )
  }

  const hasStoreImage: boolean = !!trip.store.iconUrl

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div className="header">
        {hasStoreImage && (
          <img
            src={`${window.SC.apiUrl}/v2/stores/${trip.store.id}/logo`}
            alt="logo"
            className="store-logo"
          />
        )}
        <div>
          <p
            className="text-dark mb-0"
            style={hasStoreImage ? { display: 'flex', alignItems: 'center' } : undefined}
          >
            {trip.store.name}
          </p>
          {trip.reference && <p>Ref. #: {`${trip.reference}`}</p>}
        </div>
      </div>
      <DynamicMap center={center} zoom={14}>
        <StoreMarker store={trip.store} />
        <TripMarker trip={trip} token={token} />
        {vehicle && <VehicleMarker vehicle={vehicle} />}
        <TripPreview trip={trip} token={token} />
      </DynamicMap>
      <div
        style={{
          position: 'absolute',
          bottom: 30,
          left: 10,
          right: 10,
          zIndex: 99,
          background: 'white',
          border: '1px solid #ccc',
          borderRadius: 5,
          padding: 10,
          marginBottom: 10,
        }}
      >
        {trip.vehicle && trip.vehicle.driver && (
          <div>
            {trip.scheduledAt && (
              <p>
                <i className="far fa-clock" /> {t('tripTrack.scheduledFor')}{' '}
                {dayjs(trip.scheduledAt).calendar()}
              </p>
            )}
            <p id="vehicle-title">
              <span className="text-dark">{trip.vehicle.driver.firstName}</span>{' '}
              {t('tripTrack.isDriving')} {trip.vehicle.make} {trip.vehicle.model}
            </p>
            <div className="flex-row">
              <p id="vehicle-license">
                <i className="fas fa-car text-dark" />{' '}
                <span className="text-dark">{trip.vehicle.licensePlate}</span>
              </p>
              {trip.vehicle.driver.phoneNumber && (
                <p style={{ marginBottom: 0 }}>
                  <a
                    style={{ textDecoration: 'none', color: '#5878e7' }}
                    href={`tel:${trip.vehicle.driver.phoneNumber}`}
                  >
                    <i className="fas fa-phone" /> {t('tripTrack.callDriver')}
                  </a>
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
